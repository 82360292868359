import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import { Link } from "gatsby";

const JoinTheFoundry = ({ heading, cards, marginBottom = 0 }) => {
  const Container = styled.div`
      margin-top: 100px;
      margin-bottom: ${marginBottom}px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin: 50px 0;
      }
    `,
    Header = styled.h2`
      padding: 0 80px;
      position: relative;
      line-height: 33px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        padding: 0 30px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 30px;
        line-height: 53px;
      }
    `,
    CardsContainer = styled.div`
      display: flex;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        flex-direction: column;
      }
    `,
    Card = styled.div`
      height: 400px;
      display: grid;
      grid-template: 60px 80px 1fr 60px / 80px 1fr 80px;
      width: 50%;

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-template: 60px auto 1fr 60px / 50px 1fr 50px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: 30px auto 1fr 60px / 30px 1fr 30px;
        width: 100%;
      }
    `,
    CardTitle = styled.h2`
      grid-area: 2 / 2 / 2 / 2;
    `,
    CardText = styled.p`
      grid-area: 3 / 2 / 3 / 2;
      font-size: 20px;
      line-height: 25px;
    `,
    CardLinkContainer = styled.div`
      grid-area: span 3 / 2 / span 3 / 3;
      display: flex;
      align-items: center;
    `,
    CardLink = styled(Link)`
      font-size: 1em;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 1.25em;
      border-bottom: 4px solid;
      cursor: pointer;
      padding-bottom: 0.2em;
      margin-bottom: 25px;
    `;
  return (
    <Container>
      <Header>{heading || ""}</Header>
      <CardsContainer>
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              className={
                index % 2 === 0
                  ? "course-catalog-primary-single-card"
                  : "course-catalog-light-grey-single-card"
              }
            >
              <CardTitle>{card?.title}</CardTitle>
              <CardText>{card?.description?.description}</CardText>
              <CardLinkContainer>
                <CardLink to={card?.link?.url} className={ "main"}>
                  {card?.link?.linkText}
                </CardLink>
              </CardLinkContainer>
            </Card>
          );
        })}
      </CardsContainer>
    </Container>
  );
};

JoinTheFoundry.propTypes = {};

export default JoinTheFoundry;
