import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Swipeable } from "react-swipeable";
import Img from "gatsby-image";
import { theme } from "../../utils/global-styles/theme";
import Bubble from "../BubbleSlider/child-components/Bubble";
import { css } from "@emotion/core";

const TestimonialWithImage = ({
  testimonials,
  programs,
  isHomePage = false,
}) => {
  const [activeBubbleIndex, setActiveBubbleIndex] = useState(0),
    manuallyChangeSlide = index => {
      if (index < 0) {
        setActiveBubbleIndex(testimonials.length - 1);
      } else if (index >= testimonials.length) {
        setActiveBubbleIndex(0);
      } else {
        setActiveBubbleIndex(index);
      }
    },
    buildBubbles = (totalCount, activeIndex, setActive) => {
      const bubbles = [];
      for (let i = 0; i < totalCount; i++) {
        bubbles.push(
          <Bubble
            active={activeIndex === i}
            setActive={setActive}
            index={i}
            key={i}
            fillColor={"primary-fill"}
            strokeColor={"primary-stroke"}
          />
        );
      }
      return bubbles;
    };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveBubbleIndex((activeBubbleIndex + 1) % testimonials.length);
    }, 8000);
    return () => clearTimeout(timerId);
  }, [activeBubbleIndex, testimonials.length]);

  let marginBottom = "12em";

  if (programs?.length || isHomePage) {
    marginBottom = "0";
  }
  const TestimonialContainer = styled(Swipeable)`
      display: flex;
      height: 450px;
      margin-bottom: ${marginBottom};
      background-color: ${theme.colors.secondary};
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        flex-direction: column;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 700px;
      }

      .fadein {
        -webkit-animation: fadein 2s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s ease-in-out; /* Firefox < 16 */
        -ms-animation: fadein 2s ease-in-out; /* Internet Explorer */
        -o-animation: fadein 2s ease-in-out; /* Opera < 12.1 */
        animation: fadein 2s ease-in-out;

        @keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @-moz-keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @-webkit-keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @-ms-keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @-o-keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    `,
    AuthorImage = styled(Img)`
      width: 100%;
      max-width: 450px;

      @media only screen and (max-width: ${theme.breakpoints.iPadPro}) {
        max-width: unset;
      }
    `,
    QuoteContainer = styled.div`
      display: grid;
      margin: 2em;
      grid-template-rows: auto 60px 60px;

      @media only screen and (max-width: ${theme.breakpoints.iPadPro}) {
        grid-template-rows: unset;
      }
    `,
    QuoteText = styled.h2`
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.04px;
      line-height: 48px;
      padding-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 27px;
        font-weight: bold;
        letter-spacing: -1.04px;
        line-height: 31px;
      }
    `,
    AuthorInfoContainer = styled.div`
      grid-area: 2 / 1 / span 1 / span 1;
      h3:nth-child(2) {
        font-size: 23px;
      }
    `,
    AuthorInfoText = styled.h3`
      font-size: 30px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 24px;
        line-height: 26px;
      }
    `,
    BubbleContainer = styled.div`
      grid-area: 3 / 1 / span 1 / span 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    `;

  return (
    <TestimonialContainer
      programs={programs}
      css={css`
        padding: ${testimonials[activeBubbleIndex].image ? "0" : "0 200px"};
        @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
          padding: 0;
          height: ${testimonials[activeBubbleIndex].image ? "800px" : "auto"};
        }
        @media only screen and (max-width: ${theme.breakpoints.mobile}) {
          padding: 0;
        }
      `}
      className="secondary-bg"
    >
      {testimonials[activeBubbleIndex].image && (
        <AuthorImage
          css={css`
            @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
              height: ${testimonials[activeBubbleIndex].image ? "800px" : ""};
            }
          `}
          className="fadein"
          fluid={testimonials[activeBubbleIndex].image.fluid}
        />
      )}
      <QuoteContainer>
        <QuoteText className="fadein">
          {testimonials[
            activeBubbleIndex
          ].testimonialQuote?.testimonialQuote?.slice(0, 1)}
          {testimonials[
            activeBubbleIndex
          ].testimonialQuote?.testimonialQuote?.slice(1)}
        </QuoteText>
        <AuthorInfoContainer className="fadein">
          <AuthorInfoText>
            {testimonials[activeBubbleIndex].authorTitle !== " "
              ? `${testimonials[activeBubbleIndex]?.authorName},`
              : testimonials[activeBubbleIndex]?.authorName}
          </AuthorInfoText>
          {testimonials[activeBubbleIndex]?.authorTitle !== " " && (
            <AuthorInfoText>
              {`${testimonials[activeBubbleIndex]?.authorTitle}`}
            </AuthorInfoText>
          )}
        </AuthorInfoContainer>
        <BubbleContainer>
          {buildBubbles(
            testimonials.length,
            activeBubbleIndex,
            manuallyChangeSlide
          )}
        </BubbleContainer>
      </QuoteContainer>
    </TestimonialContainer>
  );
};

TestimonialWithImage.propTypes = {};

export default TestimonialWithImage;
